.cards {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	flex-wrap: wrap;
	justify-content: center;
	gap: 2.5rem;
	width: 90%;
	max-width: 1000px;
	margin: 1rem auto;
}

.card {
	border-radius: 16px;
	box-shadow: 0 30px 30px -25px rgba(#4133B7, .25);
	max-width: 300px;
}

.information {
	background-color: var(--c-white);
	padding: 1.5rem;

	.tag {
		display: inline-block;
		background-color: var(--c-forest);
		color: var(--c-lightgreen);
		font-weight: 600;
		font-size: 0.80rem;
		padding: 0.5em 0.75em;
		line-height: 1;
		border-radius: 6px;
		& + * {
			margin-top: .6rem;
		}
	}

	.tag2 {
		display: inline-block;
		background-color: var(--c-indigo);
		color: var(--c-titan-white);
		font-weight: 600;
		font-size: 0.80rem;
		padding: 0.5em 0.75em;
		line-height: 1;
		border-radius: 6px;
		& + * {
			margin-top: .6rem;
		}
	}

	.title {
		font-size: 1.3rem;
		color: var(--c-forest);
		line-height: 1.25;
		margin: 0 0 0.5rem;
	}

	.smol {
		font-weight: 500;
	}

	.smoler {
		font-size: 0.9rem;
		font-weight: 500;
	}

	.info {
		color: var(--c-charcoal);
		font-weight: 500;
	}

	.button2 {
		background-color: var(--c-white);
		border: 2px solid var(--c-indigo);
		color: var(--c-indigo);
		padding: 0.5em 1em;
		border-radius: 6px;
		font-weight: 500;
		display: inline-flex;
		align-items: center;
		text-decoration: none;
		justify-content: space-between;
		gap: 0.5rem;
		&:hover {
			background-color: var(--c-indigo);
			color: var(--c-white);
		}
	}

	.button {
		background-color: var(--c-white);
		border: 2px solid var(--c-forest);
		color: var(--c-forest);
		padding: 0.5em 1em;
		border-radius: 6px;
		font-weight: 500;
		display: inline-flex;
		align-items: center;
		text-decoration: none;
		justify-content: space-between;
		gap: 0.5rem;
		&:hover {
			background-color: var(--c-forest);
			color: var(--c-titan-white);
		}
	}
	
	.details {
		display: flex;
		gap: 1rem;
		div {
			padding: .75em 1em;
			background-color: var(--c-titan-white);
			border-radius: 8px;
			display: flex;
			flex-direction: column-reverse;
			gap: .125em;
			flex-basis: 50%;
		}
		
		dt {
			font-size: .875rem;
			color: var(--c-cold-purple);
		}
		
		dd {
			color: var(--c-indigo);
			font-weight: 600;
			font-size: 1.25rem;
		}
	}
}