@import url("https://fonts.googleapis.com/css2?family=Spline+Sans:wght@300;400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Kreon:wght@300;400;500;600;700&display=swap');

*,
*:after,
*:before {
	box-sizing: border-box;
}

:root {
	/* Basic */
	--c-white: #fff;
	--c-black: #000;

	/* Greys */
	--c-ash: #eaeef6;
	--c-charcoal: #818080;
	--c-void: #141b22;

	/* Beige/Browns */
	--c-fair-pink: #FFEDEC;
	--c-apricot: #FBC8BE;
	--c-coffee: #754D42;
	--c-del-rio: #917072;

	/* Greens */
	--c-java: #1FCAC5;
	--c-lightgreen: #ebf8e6;
	--c-lightforest: #45a74a;
	--c-forest: #2E7D32;

	/* Purples */
	--c-titan-white: #f6f3ff;
	--c-cold-purple: #a69fd6;
	--c-indigo: #6558d3;
	--c-governor: #4133B7;

	/* Reds */
	--c-rose-pink: #ff6666;
	--c-cherry: #FB0000;
	--c-dried-blood: #750000;
	--c-test: #910000;
}

body {
	font-family: "Spline Sans", sans-serif;
	line-height: 1.5;
	min-height: 100vh;
	background-color: var(--c-ash);
}

.App {
  .header {
    display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	flex-wrap: wrap;
	justify-content: center;
	width: 90%;
	max-width: 1000px;
	margin: 1.6rem auto;
	text-align: center;
	font-family: "Kreon", sans-serif;
    font-weight: 600;
    font-size: 2.2rem;
	.announcement {
		font-size: 1.2rem;
		text-align: center;
		border: 2px solid var(--c-lightforest);
		border-radius: 20px;
		width: 100%;
		padding: 10px;
		background-color: var(--c-lightgreen);
		a {
			color: var(--c-lightforest);
			text-decoration: none;
			font-weight: 600;
		}
		a:hover {
			color: var(--c-forest);
			text-decoration: underline;
			text-underline-offset: 3px;
		}
	}
  }
  .lastupdated {
	margin-top: 15px;
    text-align: center;
    font-size: 0.9rem;
	font-weight: 500;
	img {
	  margin-top: 10px;
	}
  }
}
